export default {
  formatTime(value: number) {
    return new Date(Math.floor(value)).toLocaleString("en-US", {
      hour: "numeric",
      minute: "2-digit"
    })
  },
  formatDate(date: number, formatting?: object): string {
    if (formatting) {
      return new Date(date).toLocaleDateString("en-US", formatting).replace(/\//g, '-')
    } else {
      return new Date(date).toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric"
      }).replace(/\//g, '-')
    }
  }
}
